
//    Font Family

$primary-font	: 'Poppins', sans-serif;

/*--------------------------- Color variations ----------------------*/

$primary-color	: #f41068;
$title-color	: #222222;
$text-color		: #777777;

$white			: #fff;
$offwhite		: #f9f9ff;
$black			: #000;






