@media(max-width: 992px){
	.navbar-nav {
	    height: auto;
	    max-height: 400px;
	    overflow-x: hidden;
	}
}
.default-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
}
.menu-bar {
	cursor: pointer;
	span {
		color: $black;
		font-size: 24px;
	}
}

.main-menubar{
	display:none!important;
}

@include media-breakpoint-down(md) {
	.main-menubar{
		display:block !important;
	}
}


.menu-top{
	li{
		display:inline-block;
		@include transition();
		&:hover{
			background-color: $primary-color;
		}
	}
}




.navbar{

}


.navbar-nav{
	a{
		text-transform:uppercase;
		font-weight:600;
		color:$black;
		padding: 20px;
		&:hover{
			color:$primary-color;
		}
	}
	@media(max-width:992px){
		margin-top:10px;
		a{
			padding:0;
		}
		li{
			padding:15px 0;
		}
	}
}

.menutop-wrap{
	background-color:$offwhite;
	.menu-top .list li{
		padding:15px 15px;
		text-transform:uppercase;
			cursor:pointer;
		}	
		a{	
			font-size:12px;			
			&:hover{	
				color:#fff;
			}
			font-weight:600;
			color:#222;
			text-transform:uppercase;
			@media(max-width:462px){
				font-size:8px;
				padding:5px 0px;
			}
		}
	}



