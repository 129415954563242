.section-gap {
	padding: 120px 0;
}
.section-title {
	padding-bottom: 30px;
	h2 {
		margin-bottom: 20px;
	}
	p {
		font-size: 16px;
		margin-bottom: 0;
		@include media-breakpoint-down (md) {
			br {
				display: none;
			}
		}
	}
}

.p1-gradient-bg {
  background-image: -moz-linear-gradient( 45deg, rgb(246,70,61) 0%, rgb(246,57,141) 45%, rgb(245,44,220) 100%);
  background-image: -webkit-linear-gradient( 45deg, rgb(246,70,61) 0%, rgb(246,57,141) 45%, rgb(245,44,220) 100%);
  background-image: -ms-linear-gradient( 45deg, rgb(246,70,61) 0%, rgb(246,57,141) 45%, rgb(245,44,220) 100%);
}



.p1-gradient-color {
  background: -moz-linear-gradient( 45deg, rgb(246,70,61) 0%, rgb(246,57,141) 45%, rgb(245,44,220) 100%);
  background: -webkit-linear-gradient( 45deg, rgb(246,70,61) 0%, rgb(246,57,141) 45%, rgb(245,44,220) 100%);
  background: -ms-linear-gradient( 45deg, rgb(246,70,61) 0%, rgb(246,57,141) 45%, rgb(245,44,220) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.primary-btn {
	background-color:$white;

	border-radius:20px;
	padding: 8px 35px;
	border: 1px solid transparent;

	@include transition();
	a{	
		font-weight: 600;
		color:$black;	
	}
	&:hover{
		cursor:pointer;
		background-color:$primary-color;
		a{
			color:$white;
		}
	}	
}

.primary-btn2{
	@extend .p1-gradient-bg;
	display: inline-block;
	outline: none;
	line-height: 28px;
	padding: 0 30px;
	color:$white;
	border:1px solid #transparent;
	font-size: .8em;
	text-align: center;
	text-decoration: none;
	font-weight: 500;
	cursor: pointer;
	@include transition();
	&:hover{
		color:$white;
	}
	&:focus {
		outline: none;
	}
	&.e-large {
		padding: 0 40px;
		line-height: 50px;
	}
	&.large {
		line-height: 45px;
	}
	&.medium {
		line-height: 30px;
	}
	&.small {
		line-height: 25px;
	}
	&.radius {
		border-radius: 3px;
	}
	&.circle {
		border-radius: 20px;
	}
	&.arrow {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		span {
			margin-left: 10px;
		}
	}
	&.primary-border {
		color: $black;
		font-weight:600;
		border: 1px solid $text-color;
		background: $white;
		&:hover {
			color: $white;
			@extend .p1-gradient-bg;
			border:1px solid #fff;
			border: none;
		}
	}
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


//--------- Start Banner Area -------------//

.default-header{
	background-color:#fff;
	width: 100% !important;
	box-shadow: -21.213px 21.213px 30px 0px rgba(158,158,158,0.3);	
}

.sticky-wrapper{
	height:48px !important;
}


.dropdown-item {
	font-size: 14px;
    width: auto !important;
    text-align:left;
    @include transition();
    @media(max-width:767px){
	    text-align:left;
		padding: 0.25rem;   
    }
 
}

@media (min-width:768px) {
	.dropdown .dropdown-menu{
		display: block;
		opacity:0;
		@include transition();
	}
	.dropdown-menu{
		@include transition();
	}

	.dropdown:hover .dropdown-menu {
	    display: block;
	    opacity: 1;
	    @include transition();
	}
}


.dropdown-menu{
    border-radius:0;
    margin-top:15px;
    border:none;
     a{
		padding: 5px 15px;    	
    }
    @media(max-width:767px){
		margin-top:0px;
    }
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: transparent;
}


.banner-area {
	@extend .p1-gradient-bg;
	.fullscreen {
		@include media-breakpoint-down (sm) {
			height: 700px !important;
		}
	}
}
.banner-content {
	@include media-breakpoint-down(md) {
		text-align:center;
	}
	.title-top{
		font-size:48px;
		font-weight:400;
		span{
			font-size:18px;
		}
	}
	h1{
		color:$white;
		font-size: 72px;
		font-weight: 700;
		line-height: 1.15em;
		margin-bottom:20px;	
		@include media-breakpoint-down(md) {
			font-size: 36px;
		}
		br {
			@include media-breakpoint-down (md) {
				display: none;
			}
		}

		@include media-breakpoint-down (lg) {
				font-size:45px;
		}	

		@media ( max-width:414px ) {
			font-size:40px;
		}
	}

	p {
		margin-bottom: 30px;
		font-size:14px;
		font-weight:300;
		max-width:730px;
		br {
			@include media-breakpoint-down (md) {
				display: none;
			}
		}
	}


}


@include media-breakpoint-down(md) {
	.img-right img{
		width:50%;
		margin-left: auto;
		margin-right: auto;
	}
}
@include media-breakpoint-down(sm) {
	.img-right img{
		width: 90%;
		margin-top: 40px;
	}
}


//--------- End Banner Area -------------//

//--------- start category Area -------------//

.category-area{
	.content {
	  position: relative;
	  max-width: 100%;
	  margin: auto;
	  overflow: hidden;
	}

	.content .content-overlay {
	  @extend .p1-gradient-bg;
	  position: absolute;
	  left: 0;
	  top: 0;
	  bottom: 0;
	  right: 0;
	  opacity: 0;
	  -webkit-transition: all 0.4s ease-in-out 0s;
	  -moz-transition: all 0.4s ease-in-out 0s;
	  transition: all 0.4s ease-in-out 0s;
	}

	.content-overlay{
		margin:20px;
	}

	.content:hover .content-overlay{
	  opacity: .8;
	}


	.content-details {
	  position: absolute;
	  text-align: center;
	  padding-left: 1em;
	  padding-right: 1em;
	  width: 100%;
	  top: 50%;
	  left: 50%;
	  opacity: 0;
	  -webkit-transform: translate(-50%, -50%);
	  -moz-transform: translate(-50%, -50%);
	  transform: translate(-50%, -50%);
	  -webkit-transition: all 0.3s ease-in-out 0s;
	  -moz-transition: all 0.3s ease-in-out 0s;
	  transition: all 0.3s ease-in-out 0s;
	}

	.content-title{
		font-size:18px;
		color:$white;
		font-weight:500;
	}

	.content:hover .content-details{
	  top: 50%;
	  left: 50%;
	  opacity: 1;
	}


	.content-image{
		width:100%;
	}

	.content-details p{
	  color: #fff;
	  font-size: 0.8em;
	}

	.fadeIn-bottom{
	  top: 80%;
	}

	@media(max-width:1024px) {
		.category-bottom .mb-30{
			margin-bottom: 22px;
		}
	}

	@media(max-width:800px) {
		.category-bottom .mb-30{
			margin-bottom: 14px;
		}
	}


}

//--------- end category Area -------------//


//--------- start men product -------------//

.men-product-area{
	background-image:url(../img/men-bg.jpg);
	.overlay-bg{
		@extend .p1-gradient-bg;
		opacity:.8;
	}
	.menu-content{
		z-index:2;
	}
}

.single-product{
	.price{
		h5{
			margin-top:15px;
			margin-bottom:15px;
			font-weight:600;
		}
		h3{
			font-weight:700;
		}
		@media(max-width:768px){
			text-align:center;
		}
	}

	@media(max-width:800px){
		margin-bottom: 20px;
	}
}



//--------- end men product -------------//


//--------- start women product -------------//

.women-product-area{

}

.single-women-product{

}


.content {
  position: relative;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background-color:$black;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay{
  opacity: .5;
}


.content-image{
	width:100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}



.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fadeIn-bottom{
  top: 80%;
}

.bottom {
	@include transition();
	@extend .p1-gradient-bg;
	a {
		display: inline-block;
		width: 25%;
		line-height: 40px;
		text-align: center;
		span {
			color: $white;
		}
		&:hover {
			span {
				color: $title-color;
			}
			background: $white;
			box-shadow: 2.828px 2.828px 15px 0px rgba(0, 0, 0, 0.1);
		}
	}
	@include transition (all .3s linear);
}


//--------- end women product -------------//


//-------- Start Countdown----------//

.countdown-area{
	background: $offwhite;
	position: relative;
	padding: 80px 0;
	.countdown-content {
		position: relative;
		z-index: 3;
	}
	.countdown {
		background: $white;
		border-radius: 3px;
		padding: 35px;
		margin-top: 30px;
		position: relative;
		@include media-breakpoint-down (sm) {
			padding: 15px;
		}
		.view-btn {
			position: absolute;
			right: 20px;
			bottom: -20px;
			@include media-breakpoint-down (sm) {
				bottom: -30px;
			}
		}
		.cd-img {
			position: absolute;
			left: -40%;
			top: 28%;
			transform: translateY(-50%);
			@include media-breakpoint-down (md) {
				left: 0;
				width: 250px;
			}
			@include media-breakpoint-down (sm) {
				display: none;
			}
		}
		.countdown-item {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			padding: 0 25px;
			@include media-breakpoint-down (sm) {
				padding: 0 15px;
			}
			position: relative;
			&:before {
				position: absolute;
				content: "";
				width: 1px;
				height: 40px;
				background: #eee;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&:first-child {
				padding-left: 0;
				&:before {
					width: 0px;
				}
			}
			&:last-child {
				padding-right: 0;
			}
			.countdown-timer {
				font-size: 36px;
				font-weight: 700;
				line-height: 36px;
				margin-bottom: 5px;
				@include media-breakpoint-down (sm) {
					font-size: 24px;
					line-height: 30px;
				}
				@extend .p1-gradient-color;
			}
			.countdown-label {

			}
		}
	}

	.view-btn{
		font-weight:600;
		@include transition();
		&:hover{
			@extend .p1-gradient-bg;
			color:$white;
		}		
	}
}

//-------- End Countdown ----------//


//--------- start related product  -------------//

.related-content{
	margin-bottom:80px;
}

.single-related-product{
	.desc{
		margin:15px;
	}
	.price{
		font-weight:600;
		color:$black;
	}
	a{
		color:$black;
	}
}

.logo-wrap{
  padding:60px 65px;
  background-color: rgb(255, 255, 255);
  box-shadow: -21.213px 21.213px 30px 0px rgba(157, 157, 157, 0.2);
}

//--------- end related product  -------------//


//--------- start brand Area -------------//

.single-img{
	img{
		@include filter(grayscale, 100%);

		@include transition();
		&:hover{
			@include filter(grayscale, 0%);
		}
		@media(max-width:800px){
			margin-bottom:40px;
		}
	}
}

//--------- end brand Area -------------//


//--------- start footer Area -------------//

	.footer-area{
		padding-top:100px;
		background-color:#222222;
		h6{
			color:#fff;
			margin-bottom:25px;
			font-size:18px;
			font-weight:600;
		}
	}	

	.copy-right-text{
		i,a{
			color:$primary-color;
		}
	}


	.instafeed {
		margin: -5px;
		li {
			overflow: hidden;
			width: 25%;
			img {
				margin: 5px;
			}
		}
	}


	.footer-social{
		a{
			padding-right:25px;
			@include transition();
			&:hover{
			
				i{
						@extend .p1-gradient-color;
				}
			}
		}
		
		i{
			color:#cccccc;
			@include transition();

		}
		@include media-breakpoint-down(md) {
			text-align:left;
		}
	}


	.single-footer-widget {
		input {
			border: none;
			width:80% !important;
			font-weight: 300;
			background: #191919;
			color:#777;
			padding-left:20px;
			border-radius: 0;
			font-size: 14px;
			&:focus {
    			background-color: #191919;
			}
		}



		.bb-btn{
			@extend .p1-gradient-color;
			color:#fff;
			font-weight:300;
			border-radius:0;
			z-index:9999;
			cursor:pointer;
		}



		.info {
				position:absolute;
				margin-top:20%;
				color: #fff;
				font-size: 12px;
				&.valid {
					color: green;
				}
				&.error {
					color: red;
				}

		}

		.click-btn{
			@extend .p1-gradient-bg;
			color: #fff;
			border-radius: 0;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			padding: 8px 12px;
			border:0;
		}

		::-moz-selection { /* Code for Firefox */
	  	 	 background-color: #191919!important;
	  		 color: $text-color;
		}
		::selection {
		    background-color: #191919!important;
		    color: $text-color;
		}
		::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		    color:    $text-color;
		    font-weight: 300;
		}
		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		   color:    $text-color;
		   opacity:  1;
		   font-weight: 300;
		}
		::-moz-placeholder { /* Mozilla Firefox 19+ */
		   color:    $text-color;
		   opacity:  1;
		   font-weight: 300;
		}
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
		   color:    $text-color;
		   font-weight: 300;
		}
		::-ms-input-placeholder { /* Microsoft Edge */
		   color:    $text-color;
		   font-weight: 300;
		}

		@include media-breakpoint-down(md) {
			margin-bottom:30px;
		}
	}
		

	.footer-text{
		padding-top:80px;
		a,i{
			color:$primary-color;
		}
	}

//--------- end footer Area -------------//


//----------- Start Generic Page -------------//

.whole-wrap{
	background-color:$white;
}


.generic-banner {
	@extend .p1-gradient-bg;
	.height {
		height: 600px;
		@include media-breakpoint-down (sm) {
			height: 400px;
		}
	}
	.generic-banner-content {
		h2 {
			line-height: 1.2em;
			margin-bottom: 20px;
			br {
				@include media-breakpoint-down (md) {
					display: none;
				}
			}
		}
		p {
			text-align:center;
			font-size: 16px;
			br {
				@include media-breakpoint-down (md) {
					display: none;
				}
			}
		}
	}
}

.generic-content{
	h1{
		font-weight:600;	
	}
}


.about-generic-area {
	background: $white;
	.border-top-generic {
		border-top: 1px dotted #eee;
	}
	p {
		margin-bottom: 20px;
	}
}
.white-bg {
	background: $white;
}
.section-top-border {
	padding: 70px 0;
	border-top: 1px dotted #eee;
}
.switch-wrap {
	margin-bottom: 10px;
	p {
		margin: 0;
	}
}
//----------- End Generic Page -------------//


// ========= single page style ========= // 


.quick-view-content {
	padding: 20px 50px 20px 10px;
	@include media-breakpoint-down (md) {
		padding: 50px;
	}
	@include media-breakpoint-down (sm) {
		padding: 30px 15px;
	}
	.head {
		margin-bottom: 10px;
		font-weight: 400;
	}
	.price {
		font-weight: 700;
		margin-bottom: 15px;
		span {
			@extend .p1-gradient-color;
		}
		font-size: 24px;
	}
	.category {
		span {
			@extend .p1-gradient-color;
		}
	}
	.available {
		span {
			color: $title-color;
		}
	}
	.content {
		margin: 20px 0;
	}
	.top {
		padding-bottom: 25px;
		border-bottom: 1px dotted #eee;
	}
	.view-full {
		@extend .p1-gradient-color;
	}
	.middle {
		padding-bottom: 25px;
		border-bottom: 1px dotted #eee;
	}
	.bottom {
		padding: 25px 0;
		.view-btn {
			padding: 0 30px;
		}
	}
	.color-picker {
		.single-pick {
			width: 15px;
			height: 15px;
			border-radius: 3px;
			
			display: inline-block;
			margin: 0 5px;
			&:nth-child(1) {
				margin-left: 10px;
				background: #9906e5;
			}
			&:nth-child(2) {
				background: #ff6600;
			}
			&:nth-child(3) {
				background: #03c197;
			}
			&:nth-child(4) {
				background: #068fe5;
			}
			&:nth-child(5) {
				background: #e506ae;
			}
		}
	}
	.like-btn {
		width: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 3px;
		background: #f9fafc;
		margin-left: 10px;
		span {
			color: $title-color;
		}
		&:hover {
			background: $white;
			box-shadow: 2.828px 2.828px 15px 0px rgba(0, 0, 0, 0.1);
		}
	}
	
}



.top-section-area{	
	@extend .p1-gradient-bg;
.top-left{
	h1{
		font-size:48px;
	
		@media(max-width:768px){
			font-size:35px;
		}
	}

	ul{
		text-align:left;

		li{
			display:inline-block;
			a{
				font-size:14px;
				font-weight:400;
				color:$white;
			}
			.lnr{
				color:$white;
				font-weight: 900;
				margin:0px 20px;
			}
		}

		@media(max-width:800px){
			text-align:left;
			margin-top:30px;
		}
	}
}
}


//----------- Strat 02-11 Product Details ----------//
.details-tab-navigation {
	background: #f9fafc;
	.nav-tabs {
		border: none;
	}
	.nav-link {
		border: 1px solid #eee;
		background: $white;
		color: $black;
		padding: 0 30px;
		line-height: 35px;
		margin: 10px 3px;
		border-radius: 0px;
		&:hover {
			@extend .p1-gradient-bg;
			color: $white;
			border: 1px solid transparent;
		}
		&.active {
			@extend .p1-gradient-bg;
			color: $white;
			border: 1px solid transparent;
		}
	}
}
.description {
	padding: 30px;
	@include media-breakpoint-down (sm) {
		padding: 30px 10px;
	}
	border: 1px solid #eee;
	border-top: 0px;
}
.specification-table {
	padding: 30px;
	@include media-breakpoint-down (sm) {
		padding: 30px 10px;
	}
	border: 1px solid #eee;
	border-top: 0px;
	.single-row {
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		padding: 10px 0;
		span {
			width: 50%;
			&:first-child {
				margin-left: 50px;
				@include media-breakpoint-down (sm) {
					margin-left: 0;
				}
			}
		}
		border-bottom: 1px solid #eee;
		&:last-child {
			border-bottom: 0px;
		}
	}
}
.review-wrapper {
	padding: 30px;
	@include media-breakpoint-down (sm) {
		padding: 30px 10px;
	}
	border: 1px solid #eee;
	border-top: 0px;
}
.review-overall {
	width: 235px;
	padding: 30px 0;
	border: 1px solid #eee;
	background: #f9fafc;
	text-align: center;
	h3 {
		font-weight: 500;
	}
	.main-review {
		color: $primary-color;
		font-size: 48px;
		font-weight: 700;
		padding: 15px 0;
	}
}
.review-count {
	margin-left: 30px;
	h4 {
		margin-bottom: 5px;
	}
	@include media-breakpoint-down(xs) {
		margin-top: 30px;
	}
}
.single-review-count {
	.total-star {
		margin: 0 10px;
	}
}
.total-star {
	i {
		display: inline-block;
		margin: 0 1px;
		color: #cccccc;
	}
	&.five-star {
		i {
			&:nth-child(-n+5) {
				color: #fbd600;
			}
		}
	}
	&.four-star {
		i {
			&:nth-child(-n+4) {
				color: #fbd600;
			}
		}
	}
	&.three-star {
		i {
			&:nth-child(-n+3) {
				color: #fbd600;
			}
		}
	}
	&.two-star {
		i {
			&:nth-child(-n+2) {
				color: #fbd600;
			}
		}
	}
	&.one-star {
		i {
			&:nth-child(-n+1) {
				color: #fbd600;
			}
		}
	}
}
.total-comment {
	margin-top: 30px;
	.single-comment {
		.user-details {
			img {
				width: 70px;
				height: 70px;
				border-radius: 50%;
				margin-right: 20px;
			}
			.user-name {
				@include media-breakpoint-down (xs) {
					margin-top: 10px;
				}
				h5 {
					font-size: 16px;
					font-weight: 500;
					margin-bottom: 5px;
				}
			}
		}
		.user-comment {
			margin-top: 15px;
			margin-bottom: 30px;
		}
	}
}
.add-review {
	h3 {
		margin-bottom: 20px;
	}
}
.main-form {
	text-align: right;
	.view-btn {
		border: 0px;
		cursor: pointer;
		margin-top: 10px;
	}
}
.common-input {
	display: block;
	width: 100%;
	border: 1px solid #eee;
	line-height: 40px;
	padding: 0 30px;
	@include media-breakpoint-down (xs) {
		padding: 0 10px;
	}
	margin-top: 10px;
	&:focus {
		outline: none;
	}
	&.mt-20 {
		margin-top: 20px;
	}
}
.common-textarea {
	display: block;
	width: 100%;
	height: 100px;
	border: 1px solid #eee;
	padding: 10px 30px;
	@include media-breakpoint-down (xs) {
		padding: 10px;
	}
	margin-top: 10px;
	&:focus {
		outline: none;
	}
}
.reply-comment {
	margin-left: 30px;
}

.quick-view-carousel-details {
	.item {
		background-repeat: no-repeat !important;
		background-size: cover !important;
		background-position: center center !important;
		height: 600px;
		display: block;
		@include media-breakpoint-down (sm) {
			height: 300px;
		}
	}
	position: relative;
	.owl-controls {
		@include media-breakpoint-down(md) {
			display: none;
		}
		position: absolute;
		bottom: 20px;
		right: 20px;
		.owl-dots {
			@include flexbox();
			.owl-dot {
				width: 60px;
				height: 60px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				margin-left: 10px;
				&:nth-child(1) {
					background: url(../img/ob1.jpg);
					margin-left: 0;
				}
				&:nth-child(2) {
					background: url(../img/ob2.jpg);
				}
				&:nth-child(3) {
					background: url(../img/ob3.jpg);
				}
				&.active {
					box-shadow: 2.828px 2.828px 15px 0px rgba(0, 0, 0, 0.1);
					position: relative;
					&:after {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						@extend .p1-gradient-bg;
						opacity: .8;
						left: 0;
						top: 0;
					}
				}
			}
		}
	}
}


.organic-body {
	.quick-view-carousel {
		.owl-controls {
			.owl-dots {
				@include flexbox();
				.owl-dot {
					&:nth-child(1) {
						background: url(../img/organic-food/ob1.jpg);
						margin-left: 0;
					}
					&:nth-child(2) {
						background: url(../img/organic-food/ob2.jpg);
					}
					&:nth-child(3) {
						background: url(../img/organic-food/ob3.jpg);
					}
					&.active {
						&:after {
							@extend .p1-gradient-bg;
						}
					}
				}
			}
		}
	}

	.quick-view-content {
		.price {
			span {
				@extend .p1-gradient-color;
			}
		}
		.category {
			span {
				@extend .p1-gradient-color;
			}
		}
		.view-full {
			@extend .p1-gradient-color;
		}
	}

	.organic{
		@extend .p1-gradient-bg;
		span{
			color:#fff;
		}
		&:hover{
			@extend .p1-gradient-bg;
		}
	}

}
.organic-product-top {
	.single-product-top {
		height: 500px;
		padding: 40px;
		@include media-breakpoint-down(md) {
			padding: 20px;
		}
		background-position: center center !important;
		background-repeat: no-repeat !important;
		background-size: cover !important;
		&.middle {
			height: 235px;
		}
		.product-title {
			position: relative;
			margin-bottom: 10px;
			text-transform:uppercase;
			font-size:21px;
		}
		&:hover {
			.product-title {
				@extend .p1-gradient-color;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.middle-section{
			margin:30px 0;
		}
	}

}
.organic-product-carousel {
	.owl-stage-outer {

	}
}
.tab-navigation {
	.nav-tabs {
		border: none;
	}
	.nav-link {
		border: none;
		background: #f9fafc;
		padding: 0 30px;
		line-height: 35px;
		margin: 10px 3px;
		border-radius: 3px;
		&:hover {
			@extend .p1-gradient-color;
			box-shadow: 2.828px 2.828px 15px 0px rgba(0, 0, 0, 0.1);
		}
		&.active {
			@extend .p1-gradient-color;
			box-shadow: 2.828px 2.828px 15px 0px rgba(0, 0, 0, 0.1);
		}
	}
}
.organic-product-area {

}


.category-list{
	.single-product{
		margin-top:30px;
	}
}

//---------- Start Woocomerce Checkout Page ---------//
label {
	margin-bottom: 0;
}

.view-btn {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #eee;
	padding: 0 30px;
	cursor: pointer;
	min-width: 172px;
	&.color-2 {
		&:after {
			border-radius: 0;
		}
	}
	span {
		font-weight: 400;
	}
}


.billing-form-page {
	.common-input {
		padding: 0 15px;
	}
	.common-textarea {
		padding: 10px 15px;
	}
	.view-btn {
		border-radius: 0px;
		box-shadow: none;
		border: 1px solid #eee;
		padding: 0 30px;
		cursor: pointer;
		min-width: 172px;
		&.color-2 {
			&:after {
				border-radius: 0;
			}
		}
		span {
			font-weight: 400;
		}
	}
}
.checkput-login {
	.top-title {
		@extend .p1-gradient-bg;
		padding: 10px 15px;
		p {
			margin: 0;
			color: $white;
			a {
				color: $white;
				text-decoration: underline;
			}
		}
	}
}
.checkout-login-collapse {
	padding: 20px;
	@include media-breakpoint-down (xs) {
		padding: 20px 0;
	}
}
.pixel-checkbox {
	+ label {
		margin-left: 10px;
	}
}
.billing-title {
	border-bottom: 1px solid #ddd;
	line-height: 1.3em;
	padding-bottom: 10px;
	&.pl-15 {
		padding-left: 15px;
		@include media-breakpoint-down(xs) {
			padding-left: 0;
		}
	}
}
.billing-form {
	.common-input {
		margin-top: 20px;
	}
	.sorting {
		margin-top: 20px;
		margin-right: 0;
		.nice-select {
			width: 100%;
			.list {
				width: 100%;
			}
		}
	}
}
.order-wrapper {
	background: #f9fafc;
	padding: 20px 15px;
	.list-row {
		border-bottom: 1px solid #eee;
		padding: 10px 0;
		h6 {
			text-transform: uppercase;
		}
		.total {
			font-weight: 500;
			color: $title-color;
		}
	}
	.bold-lable {
		font-weight: 700;
		text-transform: uppercase;
		color: $title-color;
	}
	.payment-info {
		margin-top: 20px;
		background: #f5f5f5;
		border-top: 1px solid #eee;
		padding: 15px;
	}
	.terms-link {
		color: #43b253;
	}
	.pixel-checkbox {
		margin-top: 5px;
	}
}
//---------- Woocomerce Checkout Page ---------//

//---------- Start 03 01 Woocomerce Cart Page ---------//
.cart-title {
	padding-bottom: 15px;
	@include media-breakpoint-down (sm) {
		display: none;
	}
}
.cart-single-item {
	border-top: 1px solid #eee;
	padding: 15px 0;
	.product-item {
		margin-left: 15px;
		@include media-breakpoint-down(sm) {
			margin-left: 0;
		}
		img {
			border: 1px solid #eee;
			border-radius: 3px;
		}
		h6 {
			color:$black;
			margin-left: 30px;
			font-weight: 400;
		}
	}
	.price, .total {
		font-size: 18px;
		font-weight: 500;
		color: $title-color;
	}
	.quantity-container {
		.quantity-amount {
			color: $title-color;
			font-weight: 500;
			font-size: 18px;
			border-radius: 20px 0 0 20px;
			width: 60px;
		}
		.arrow-btn {
			border-radius: 0 20px 20px 0;
			overflow: hidden;
			border-left: 1px solid #eee;
			.arrow {
				padding-right: 15px;
			}
		}
	}
}
.cupon-area {
	padding: 15px;
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
	}
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	.view-btn {
		box-shadow: none;
		border-radius: 0px;
		cursor: pointer;
		border: 1px solid #eee;
		padding: 0 30px;
		&.color-2 {
			&:after {
				border-radius: 0;
			}
		}
		span {
			font-weight: 400;
		}
	}
	.cuppon-wrap {
		.view-btn {
			border-radius: 0;
			&.color-2 {
				&:after {
					border-radius: 0;
				}
			}
		}
	}
	.cupon-code {
		display: none;
		@include media-breakpoint-down (sm) {
			margin-top: 10px;
		}
		input {
			border: 1px solid #eee;
			line-height: 40px;
			padding: 0 15px;
			width: 200px;
			border-right: 0px;
			margin-right: -5px;
		}
		button {
			border-right: 0;
			@include media-breakpoint-down(xs) {
				border-right: 1px solid #eee;
			}
			cursor: pointer;
			&:focus {
				outline: none;
			}
		}
		.view-btn {
			border-radius: 0;
			&:after {
				border-radius: 0;
			}
		}
	}
}
.subtotal-area {
	padding: 15px;
	border-bottom: 1px solid #eee;
	.subtotal {
		margin-left: 200px;
		font-weight: 500;
		color: $title-color;
		font-size: 18px;
	}
}
.shipping-area {
	padding: 15px;
	border-bottom: 1px solid #eee;
	.filter-list {
		label {
			margin-right: 10px;
		}
	}
	.calculate {
		margin-right: 43px;
	}
	.view-btn {
		border-radius: 0px;
		box-shadow: none;
		border: 1px solid #eee;
		padding: 0 30px;
		cursor: pointer;
		width: 172px;
		&.color-2 {
			&:after {
				border-radius: 0;
			}
		}
		span {
			font-weight: 400;
		}
	}
	.sorting {
		margin-right: 0;
		width: 300px;
		.nice-select {
			width: 100%;
		}
		.list {
			width: 100%;
		}
	}
	.common-input {
		padding: 0 15px;
	}
}

.quantity-container {
	.quantity-amount {
		width: 50px;
		line-height: 36px;
		border: 1px solid #eeeeee;
		border-right: 0px;
		border-radius: 3px;
		margin-right: -3px;
		padding-left: 20px;
	}
	.arrow {
		height: 17px;
		padding-left: 15px;
		border: none;
		cursor: pointer;
		background: $white;
		&:focus {
			outline: none;
		}
		span {
			font-size: 12px;
		}
	}
	.arrow-btn {
		padding: 1px 0 3px 0;
		border: 1px solid #eeeeee;
		border-left: 0px;
		border-radius: 3px;
	}
}

//---------- End Woocomerce Cart Page ---------//

//---------- Start Woocomerce Confirmation Page ---------//
.order-rable {
	width: 100%;
	padding: 15px;
	display: block;
	@include media-breakpoint-down(xs) {
		padding: 15px 0;
	}
	tr {
		td {
			width: 50%;
			&:last-child {
				color: $title-color;
				font-weight: 500;
			}
		}
	}
}
//---------- End Woocomerce Confirmation Page ---------//

//---------- Start Woocomerce My Account Page ---------//
.login-form {
	padding: 30px;
	background: #f9fafc;
	height: 100%;
	a {
		&:hover {
			color: #44b253;
		}
	}
}
.register-form {
	@include media-breakpoint-down (sm) {
		margin-top: 30px;
	}
	padding: 30px 30px 100px 30px;
	@extend .p1-gradient-bg;
	.billing-title {
		color: $white;
		border-bottom: 1px solid $white;
	}
	p {
		color: $white;
	}
	.common-input {
		border: 1px solid rgba($white, .3);
		background: transparent;
		color: $white;
		&:focus {
			border: 1px solid rgba($white, 1);
		}
	}
	::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color:    #fff;
		font-weight: 300;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color:    #fff;
		opacity:  1;
		font-weight: 300;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		color:    #fff;
		opacity:  1;
		font-weight: 300;
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color:    #fff;
		font-weight: 300;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color:    #fff;
		font-weight: 300;
	}
}


//---------- End Woocomerce My Account Page ---------//
//---------- Start Woocomerce Order Tracking Page ---------//
.order-tracking {
	padding: 30px;
	background: #f9fafc;
}
//---------- End Woocomerce Order Tracking Page ---------//
